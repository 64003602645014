import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
    BorderColorOutlined,
    DeleteOutline,
    DragIndicatorOutlined,
    Refresh,
    SlowMotionVideo
} from '@mui/icons-material';
import { Box, CardHeader, CircularProgress, IconButton, Checkbox } from '@mui/material';
import { green } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useDraggable } from '@dnd-kit/core';

export default function ServerCard({
    isRebooting: initialIsRebooting,
    data,
    id,
    setSelected,
    handleDeleteServerModalOpen,
    handleEditServerModalOpen,
    groupId,
    reboot,
    isSelected,
    onServerSelection
}) {
    const navigate = useNavigate();
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: "draggable_" + id,
        data: data
    });

    const [isRebooting, setIsRebooting] = useState(initialIsRebooting);
    const [timeLeft, setTimeLeft] = useState(null);
    const [timerId, setTimerId] = useState(null);

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1,
        position: "absolute"
    } : undefined;

    useEffect(() => {
        setIsRebooting(initialIsRebooting);
    }, [initialIsRebooting]);

    return (
        <Card sx={{
            minWidth: {
                sm: 100,
                md: 275
            },
            backgroundColor: "white"
        }} ref={setNodeRef} style={style}>
            <CardHeader
                avatar={
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={isSelected}
                            onChange={() => onServerSelection(data._id)}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <IconButton aria-label="Server" {...listeners} {...attributes}>
                            <DragIndicatorOutlined />
                        </IconButton>
                    </Box>
                }
                action={data.online ?
                    (isRebooting ?
                        <CircularProgress color='warning' />
                        :
                        <IconButton aria-label="Restart" size='small' onClick={(e) => {
                            e.stopPropagation();
                            reboot();
                            setIsRebooting(true);

                            if (timerId) {
                                clearInterval(timerId);
                            }
                            setTimeLeft(30);
                            const newTimerId = setInterval(() => {
                                setTimeLeft((prevTimeLeft) => {
                                    if (prevTimeLeft === 1 || !data.online) {
                                        clearInterval(newTimerId);
                                        setIsRebooting(false);
                                        return null;
                                    }
                                    return prevTimeLeft - 1;
                                });
                            }, 1000);
                            setTimerId(newTimerId);
                        }}>
                            <Refresh color='warning' fontSize='large' />
                        </IconButton>
                    )
                    :
                    <IconButton aria-label="Start" size='small'>
                        <SlowMotionVideo color='success' fontSize='large' />
                    </IconButton>
                }
            />
            <CardContent sx={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                setSelected(data);
                navigate(`/groups/${groupId}/server/${data._id}`, { state: { groupId: groupId, server: data } });
            }}>
                <Typography variant="h5" component="div">
                    {data.name}
                </Typography>
                <Typography color="text.secondary">
                    {"IP: " + data.host}
                </Typography>
                <Box display="flex" justifyContent="center"><Typography color="text.secondary">{"Status: "}</Typography>{!isRebooting && data.online ?
                    <Typography fontWeight="bold" sx={{ color: green["A400"] }}>Online</Typography> :
                    <Typography fontWeight="bold" color="error">Offline</Typography>}</Box>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {"Creation date: " + new Date(data.createdAt).toLocaleDateString()}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
                <IconButton aria-label="Remove" onClick={(e) => {
                    e.stopPropagation();
                    setSelected(data);
                    handleDeleteServerModalOpen();
                }}>
                    <DeleteOutline color='error' />
                </IconButton>
                <IconButton aria-label="Edit" onClick={(e) => {
                    e.stopPropagation();
                    setSelected(data);
                    handleEditServerModalOpen();
                }}>
                    <BorderColorOutlined />
                </IconButton>
            </CardActions>
        </Card>
    );
}