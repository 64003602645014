import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import RefreshIcon from '@mui/icons-material/Refresh';
import Calendar from './Calendar';  // Adjust the import path as needed
import CalendarIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import { getBaseUrl } from "../common";
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputAdornment,
  Popover,
  Typography,
  Snackbar,
  IconButton
} from '@mui/material';
import { Search, Settings, CloudUpload, KeyboardArrowDown, GetApp } from '@mui/icons-material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ViewURLsModal from './ViewURLsModal';
import SettingsModal from './SettingsModal';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { green } from '@mui/material/colors';
export default function AddList() {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [settings, setSettings] = useState({ timezone: 'United Kingdom (GMT+1)', whitelistedIPs: [] });
  const [serverTime, setServerTime] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [status, setStatus] = useState('ShowAll');
  const [originalLists, setOriginalLists] = useState([]);
  const [filteredLists, setFilteredLists] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const baseUrl = getBaseUrl()
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [clockTime, setClockTime] = useState(null);

  useEffect(() => {
    if (serverTime) {
      const [datePart, timePart] = serverTime.split(' ');
      const [hours, minutes, seconds] = timePart.split(':');
      
      const initialDate = new Date();
      initialDate.setHours(parseInt(hours, 10));
      initialDate.setMinutes(parseInt(minutes, 10));
      initialDate.setSeconds(parseInt(seconds, 10));
      setClockTime(initialDate);
  
      const timer = setInterval(() => {
        setClockTime(prevTime => {
          const newTime = new Date(prevTime.getTime());
          newTime.setSeconds(newTime.getSeconds() + 1);
          return newTime;
        });
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [serverTime]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };
  
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);


  const compareDatesWithoutTime = (dateStr1, dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  };

  const handleSettingsUpdate = () => {
    setSnackbarMessage("Settings updated successfully");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
    fetchSettings();
    fetchData();
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${baseUrl}/settings`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        setSettings(response.data.settings);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const formatDate = (date) => {
    return moment(date).tz(settings.timezone).format('MMM DD, YYYY HH:mm:ss');
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: 'rgba(93, 95, 239, 0.1)',
    color: 'rgb(93, 95, 239)',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(93, 95, 239, 0.2)',
    },
  }));

  const handleSort = (field) => {
    let newSortDirection;
    if (field === sortField) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      setSortDirection(newSortDirection);
    } else {
      newSortDirection = 'asc';
      setSortField(field);
      setSortDirection(newSortDirection);
    }

    // Apply sorting immediately
    const sortedList = [...filteredLists].sort((a, b) => {
      if (a[field] < b[field]) return newSortDirection === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return newSortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredLists(sortedList);
  };

  const handleDelete = async (id, fileName) => {
    if (isCtrlPressed) {
      // Delete without confirmation
      await deleteFile(id);
    } else {
      setFileToDelete({ id, fileName });
      setDeleteConfirmOpen(true);
    }
  };
  
  const deleteFile = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/deleteFile/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        setSnackbarMessage("File deleted successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchData();  // Refresh the file list
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      setSnackbarMessage("Error deleting file. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const confirmDelete = async () => {
    if (fileToDelete) {
      await deleteFile(fileToDelete.id);
    }
    setDeleteConfirmOpen(false);
    setFileToDelete(null);
  };

  // Add this new function to reset filters
  const resetFilters = () => {
    setSearchTerm('');
    setDateRange({ startDate: null, endDate: null });
    setStatus('ShowAll');
  };

  const formatServerTime = (time, timezone) => {
    if (!time) return '';
    try {
      const formattedTime = moment(time).tz(timezone).format('MM/DD/YYYY HH:mm:ss');
      const timezoneName = moment.tz.zone(timezone);
      const abbr = timezoneName ? timezoneName.abbr(moment(time)) : '';
      return `${formattedTime} ${timezone}`;
    } catch (error) {
      console.error('Error formatting server time:', error);
      return moment(time).format('MM/DD/YYYY HH:mm:ss');
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getAllFiles`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('API Response:', response.data);

      const serverTime = moment.tz(response.data.serverFullTime, response.data.timezone);
      const formtedServerTime = serverTime.format('MM/DD/YYYY HH:mm:ss');
      setServerTime(formtedServerTime);
      // Process the files using the server time
      let processedFiles = response.data.files.map(file => convertFileData(file, serverTime, response.data.timezone));

      // Group files by filename
      const groupedFiles = processedFiles.reduce((acc, file) => {
        if (!acc[file.fileName]) {
          acc[file.fileName] = [];
        }
        acc[file.fileName].push(file);
        return acc;
      }, {});

      // Handle overwritten status
      Object.values(groupedFiles).forEach(group => {
        if (group.length > 1) {
          // Sort by dateUploaded in descending order
          group.sort((a, b) => new Date(b.dateUploaded) - new Date(a.dateUploaded));

          // Check if there are any active or pending files
          const hasActiveOrPending = group.some(file => file.status === 'Active' || file.status === 'Pending');

          if (hasActiveOrPending) {
            // Mark older files as overwritten
            for (let i = 1; i < group.length; i++) {
              if (group[i].status === 'Active' || group[i].status === 'Pending') {
                group[i].status = 'Overwritten';
              }
            }
          }
        }
      });

      // Flatten the grouped files back into an array
      processedFiles = Object.values(groupedFiles).flat();

      // Sort all files by dateUploaded in descending order
      const sortedFiles = processedFiles.sort((a, b) => new Date(b.dateUploaded) - new Date(a.dateUploaded));

      console.log('Processed Data:', sortedFiles);
      setOriginalLists(sortedFiles);
      setFilteredLists(sortedFiles);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterLists();
  }, [searchTerm, dateRange, status]);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const convertFileData = (file, serverTime, timezone) => {
    if (!file || !file.sheets || file.sheets.length === 0) {
      return null;
    }

    const excelDateToJSDate = (serial) => {
      if (isNaN(serial)) return null;
      return new Date((Number(serial) - 25569) * 86400 * 1000);
    };

    const formatDateWithoutTime = (date) => {
      if (!date) return 'Invalid Date';
      return date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    };

    let earliestStart = null;
    let firstSheetEndDate = null;
    let otherEndDates = [];

    file.sheets.forEach((sheet, index) => {
      const sheetData = sheet._doc || sheet;
      const startDate = excelDateToJSDate(sheetData.dateStart);
      const endDate = excelDateToJSDate(sheetData.dateEnd);

      if (startDate && (!earliestStart || startDate < earliestStart)) {
        earliestStart = startDate;
      }

      if (index === 0) {
        firstSheetEndDate = endDate;
      } else if (endDate && !compareDatesWithoutTime(endDate.toISOString(), firstSheetEndDate.toISOString())) {
        otherEndDates.push({ sheetNumber: index + 1, date: endDate });
      }
    });

    const serverDate = new Date(serverTime);

    const checkServerDate = moment.tz(serverTime, timezone).format('MM/DD/YYYY');
    const formatedFirstSheetEndDate = moment(firstSheetEndDate).format('MM/DD/YYYY');
    console.log(`\nserverDate: ${checkServerDate}`);
    console.log(`endDate: ${formatedFirstSheetEndDate}`);

    let status;

    if (file.status === 'Overwritten') {
      status = 'Overwritten';
    } else if (serverTime < earliestStart) {
      status = 'Pending';
    } else if (formatedFirstSheetEndDate && checkServerDate <= formatedFirstSheetEndDate) {
      status = 'Active';
    } else {
      status = 'Inactive';
    }




    const daysLeft = firstSheetEndDate ? Math.ceil((firstSheetEndDate - moment.tz(serverTime, timezone)) / (1000 * 60 * 60 * 24)) : null;

    return {
      id: file._id,
      status: status,
      dateUploaded: new Date(file.dateUploaded).toISOString(),
      fileName: file.fileName,
      dateStart: formatDateWithoutTime(earliestStart),
      dateEnd: formatDateWithoutTime(firstSheetEndDate),
      otherEndDates: otherEndDates.map(ed => ({ sheetNumber: ed.sheetNumber, date: formatDateWithoutTime(ed.date) })),
      daysLeft: daysLeft !== null ? daysLeft : null,
      sheetsCount: file.sheets.length
    };
  };

  const formatDateRange = (range) => {
    if (!range.startDate || !range.endDate) return (
      <>
        <CalendarIcon sx={{ mr: 0.5 }} />
        Select date range
      </>
    );
    const start = range.startDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' });
    const end = range.endDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' });
    return `${start} - ${end}`;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  const filterLists = () => {
    let filteredList = originalLists;

    // Filter by search term
    if (searchTerm.trim() !== '') {
      const searchRegex = new RegExp(searchTerm, 'i');
      filteredList = filteredList.filter(list => searchRegex.test(list.fileName));
    }

    // Filter by date range
    if (dateRange.startDate && dateRange.endDate) {
      filteredList = filteredList.filter(list => {
        const listDate = new Date(list.dateStart);
        return listDate >= dateRange.startDate && listDate <= dateRange.endDate;
      });
    }

    // Filter by status
    if (status !== 'ShowAll') {
      filteredList = filteredList.filter(list => list.status === status);
    }

    setFilteredLists(filteredList);
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length === 0) return;

    setIsUploading(true);

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });

    try {
      const response = await axios.post(`${baseUrl}/upload-multiple`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        setSnackbarMessage(`${selectedFiles.length} file(s) uploaded successfully`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchData();
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setSnackbarMessage('Error uploading files. Please try again.');
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false);
      e.target.value = '';
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDownload = async (id, fileName) => {
    try {
      const response = await axios.get(`${baseUrl}/downloadXlsx/${id}`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const generateSheetUrls = (fileName, sheetsCount) => {

    const urlsSplitted = Array.from({ length: sheetsCount }, (_, index) => {
      const sheetNumber = index + 1;
      return `${baseUrl}/list/${fileName}${sheetNumber}`;
    });

    const urlsTotals = Array.from({ length: sheetsCount }, (_, index) => {
      const sheetNumber = index + 1;
      return `${baseUrl}/list/${fileName}${sheetNumber}total`;
    });

    return { urlsSplitted, urlsTotals };
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Main content */}
      <Box sx={{ flexGrow: 2, p: 2 }}>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 3,
          p: 2,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 1
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {serverTime && (
 <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
 Server Date: <span style={{ fontWeight: 'normal' }}>
   {clockTime ? clockTime.toLocaleString('en-US', {
     month: 'short',
     day: '2-digit',
     year: 'numeric',
     hour: '2-digit',
     minute: '2-digit',
     second: '2-digit',
     hour12: false
   }) : ''}
 </span>
 <br />
 Timezone: <span style={{ fontWeight: 'normal' }}>{settings.timezone}</span>
</Typography>
            )}
            <Box sx={{ marginLeft: 'auto' }}>
              <Button
                variant="outlined"
                onClick={resetFilters}
                startIcon={<RefreshIcon />}
                size="small"
              >
                Reset Filters
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1, minWidth: '250px' }}>
              <Typography variant="subtitle2">File name:</Typography>
              <TextField
                placeholder="Search by file name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: '200px' }}>
              <Typography variant="subtitle2">Date range:</Typography>
              <Button
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  textTransform: 'none',
                  color: 'text.secondary',
                  justifyContent: 'space-between',
                  minWidth: '150px',
                  height: '40px'
                }}
              >
                {formatDateRange(dateRange)}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: '200px' }}>
              <Typography variant="subtitle2">Status:</Typography>
              <Select
                value={status}
                size="small"
                sx={{
                  minWidth: '120px',
                  color: status === 'ShowAll' ? 'inherit' :
                    status === 'Active' ? 'success.main' :
                      status === 'Inactive' ? 'error.main' :
                        status === 'Overwritten' ? 'purple' : 'warning.main'
                }}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="ShowAll">Show All</MenuItem>
                <MenuItem value="Active" sx={{ color: 'success.main' }}>Active</MenuItem>
                <MenuItem value="Pending" sx={{ color: 'warning.main' }}>Pending</MenuItem>
                <MenuItem value="Inactive" sx={{ color: 'error.main' }}>Inactive</MenuItem>
                <MenuItem value="Overwritten" sx={{ color: 'purple' }}>Overwritten</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>

        {/* Rest of the code remains the same */}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Calendar
            onDateRangeChange={handleDateRangeChange}
            initialStartDate={dateRange.startDate}
            initialEndDate={dateRange.endDate}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <Button onClick={handleClose} color="primary" size="small">
              Close
            </Button>
          </Box>
        </Popover>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Button
            startIcon={<Settings />}
            variant="contained"
            onClick={() => setIsSettingsModalOpen(true)}
          >
            Settings
          </Button>
          <input
            accept=".xlsx"
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            disabled={isUploading}
            multiple
          />
          <label htmlFor="file-upload">
            <Button
              startIcon={<CloudUpload />}
              variant="contained"
              color="primary"
              component="span"
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Upload Files'}
            </Button>
          </label>
        </Box>

        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { field: 'status', label: 'Status' },
                  { field: 'fileName', label: 'File Name' },
                  { field: 'dateUploaded', label: 'Date Uploaded' },
                  { field: 'dateStart', label: 'Date Start' },
                  { field: 'dateEnd', label: 'Date End' },
                  { field: 'daysLeft', label: 'Days Left' },
                ].map((column) => (
                  <StyledTableCell
                    key={column.field}
                    onClick={() => handleSort(column.field)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {column.label}
                      {sortField === column.field && (
                        <span>{sortDirection === 'asc' ? ' ▲' : ' ▼'}</span>
                      )}
                    </Box>
                  </StyledTableCell>
                ))}
                <TableCell
                  style={{
                    backgroundColor: 'rgba(23, 55, 129, 0.08)',
                    color: 'rgb(23, 95, 259)',
                    fontWeight: 'bold',
                  }}
                >

                  <Box sx={{ marginLeft: '99px' }}>
                    Actions
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLists.map((list, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <span style={{
                      color: list.status === 'Active' ? 'green' :
                        list.status === 'Overwritten' ? 'purple' :
                          list.status === 'Pending' ? 'gold' :
                            'red'
                    }}>
                      {list.status}
                    </span>
                  </TableCell>
                  <TableCell>{list.fileName} <br /> <span style={{ color: 'grey', fontSize: '0.8rem' }}>{list.sheetsCount} sheets</span></TableCell>
                  <TableCell>
                    {new Date(list.dateUploaded).toLocaleString('en-US', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}
                  </TableCell>
                  <TableCell style={{ opacity: list.status === 'Overwritten' || list.status === 'Inactive' ? 0.5 : 1 }}>{list.status === 'Overwritten' ? <span style={{ color: 'grey' }}>{list.dateStart}</span> : list.dateStart}</TableCell>
                  <TableCell style={{ opacity: list.status === 'Overwritten' || list.status === 'Inactive' ? 0.5 : 1 }}>
                    {list.status === 'Overwritten' ? <span style={{ color: 'grey' }}>{list.dateEnd}</span> : list.dateEnd}
                    {list.otherEndDates.length > 0 && (
                      <Box sx={{ fontSize: '0.8rem', color: 'text.secondary', mt: 1 }}>
                        {list.otherEndDates.map((ed, idx) => (
                          <div key={idx}>
                            #{ed.sheetNumber}: {ed.date}
                          </div>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {list.status === 'Overwritten' ? (
                      <span style={{ opacity: 0.5, color: 'grey' }}>
                        {list.status === "Inactive" ?
                          (list.daysLeft === 0 ? `Expired last day` : `Expired ${-list.daysLeft} days ago`) :
                          list.daysLeft === 0 ?
                            'Last day' :
                            `${list.daysLeft} days left`
                        }
                      </span>
                    ) : list.status === "Inactive" ?
                      <span style={{ opacity: 0.5, color: 'red' }}>{(list.daysLeft === 0 ? `Expired last day` : `Expired ${-list.daysLeft} days ago`)}</span> :
                      list.daysLeft === 0 ?
                        <span style={{ opacity: 0.7 }}>Last day</span> :
                        <span style={{ opacity: 1 }}>{list.daysLeft} days left</span>
                    }
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      disabled={list.status !== 'Active' && list.status !== 'Pending'}
                      onClick={() => {
                        setSelectedFile(list);
                        setIsModalOpen(true);
                      }}
                      sx={{ opacity: list.status !== 'Active' && list.status !== 'Pending' ? 0.5 : 1 }}
                    >
                      View URLs
                    </Button>
                    <IconButton
                      sx={{
                        color: green[500],
                        marginLeft: '1.5rem',
                      }}
                      onClick={() => handleDownload(list.id, list.fileName)}
                    >
                      <GetApp />
                    </IconButton>
                    <IconButton
                    sx={{
                      color: 'error.main',
                      marginLeft: '1rem',
                      backgroundColor: isCtrlPressed ? 'rgba(244, 67, 54, 0.23)' : 'transparent',
                    }}
                    onClick={() => handleDelete(list.id, list.fileName)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {selectedFile && (selectedFile.status === 'Active' || selectedFile.status === 'Pending') && (
        <ViewURLsModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          fileData={{
            ...selectedFile,
            ...generateSheetUrls(selectedFile.fileName.split('.xlsx')[0], selectedFile.sheetsCount)
          }}
        />
      )}
      <SettingsModal
        open={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        onSettingsSaved={handleSettingsUpdate}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={confirmDelete}
        fileName={fileToDelete ? fileToDelete.fileName : ''}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
