import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Snackbar, Alert, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../common';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #d5d5d5;
`;

const StyledPaper = styled(Paper)(({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    padding: 40px;
    width: 100%;
  }
  padding: 40px;
  width: 500px;
`);

function OtpGeneration() {
    const baseUrl = getBaseUrl();
    const location = useLocation();
    const navigate = useNavigate();

    const [pinId, setPinId] = useState(location.state?.pinId || '');
    const [phoneNumber, setPhoneNumber] = useState(location.state?.phoneNumber || '');
    const [username, setUsername] = useState(location.state?.username || '');
    const [requestId, setRequestId] = useState(location.state?.requestId || '');
    const [otp, setOtp] = useState('');
    const [timeLeft, setTimeLeft] = useState(30);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(null);
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);
    const handleResendOtp = async () => {
        try {
          const response = await fetch(baseUrl + '/resend-otp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, pinId }), // Use pinId instead of requestId
          });
          const data = await response.json();
          if (data.pinId) {
            setMessage('OTP resent successfully');
            setSeverity('success');
            setTimeLeft(30);
            setPinId(data.pinId);  // Update the pinId
          } else {
            setMessage(data.message || 'Failed to resend OTP');
            setSeverity('error');
          }
          setOpen(true);
        } catch (error) {
          console.error('Resend OTP error:', error);
          setMessage('An error occurred while resending OTP. Please try again.');
          setSeverity('error');
          setOpen(true);
        }
      };
    const handleVerifyOtp = async () => {
        try {
          console.log(`Verifying OTP: username: ${username}, pin: ${otp}, pinId: ${pinId}`);
          const response = await fetch(baseUrl + '/verify-otp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, pin: otp, pinId }),
          });
          const data = await response.json();
          if (data.success) {
            setMessage('Logged in successfully');
            setSeverity('success');
            localStorage.setItem('token', data.token);
            setTimeout(() => navigate('/'), 2000);
          } else {
            setMessage(data.message || 'Verification failed');
            setSeverity('error');
          }
          setOpen(true);
        } catch (error) {
          console.error('Verification error:', error);
          setMessage('An error occurred during verification. Please try again.');
          setSeverity('error');
          setOpen(true);
        }
      };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <StyledBox>
            <StyledPaper elevation={6}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h4" component="h1">
                            OTP Verification
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h2" variant="h5" align="center">
                            We've sent you a code on {phoneNumber}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 1.5, mb: 2, width: '100%' }}>
                        <TextField
                            sx={{ mb: 2 }}
                            label="OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            fullWidth
                            variant='outlined'
                        />
                        <Button onClick={handleVerifyOtp} variant="contained" color="primary" fullWidth>
                            Verify
                        </Button>
                        {timeLeft === null ? (
                            <Button onClick={handleResendOtp} variant="outlined" color="primary" fullWidth sx={{ mt: 2 }}>
                            Resend OTP
                            </Button>
                        ) : (
                            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                Resend OTP in {timeLeft} seconds
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </StyledPaper>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </StyledBox>
    );
}

export default OtpGeneration;
