import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBaseUrl } from "../common";
import moment from 'moment-timezone';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListSubheader,
  IconButton,
  Typography,
  Box,
  Select,
  MenuItem,
  styled,
  Button,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(2),
    width: '100%',
    maxWidth: '400px',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3),
    maxHeight: 'calc(100vh - 128px)',
    overflowY: 'auto',
  },
}));

const SettingsBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.9rem',
  marginBottom: theme.spacing(1),
}));

const IPBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const IPTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
}));

const AddButton = styled(Button)(({ theme }) => ({
  minWidth: '32px',
  width: '32px',
  height: '32px',
  padding: 0,
  borderRadius: '50%',
}));

const SettingsModal = ({ open, onClose, onSettingsSaved }) => {
  const [timeZone, setTimeZone] = useState('');
  const [ipList, setIpList] = useState([]);
  const [newIP, setNewIP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const baseUrl = getBaseUrl();
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (open) {
      fetchSettings();
    }
  }, [open]);

  const fetchSettings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${baseUrl}/settings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        setTimeZone(response.data.settings.timezone);
        setIpList(response.data.settings.whitelistedIPs);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to fetch settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${baseUrl}/settings`,
        {
          timezone: timeZone,
          whitelistedIPs: ipList
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      if (response.data.success) {
        setSuccessMessage('Settings saved successfully');
        onSettingsSaved();
        onClose();
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setError('Failed to save settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddIP = () => {
    if (newIP && !ipList.includes(newIP)) {
      setIpList([...ipList, newIP]);
      setNewIP('');
    }
  };

  const handleRemoveIP = (ipToRemove) => {
    setIpList(ipList.filter(ip => ip !== ipToRemove));
  };

  const timezones = moment.tz.names();

  const groupedTimezones = timezones.reduce((acc, zone) => {
    const mainZone = zone.split('/')[0];
    if (!acc[mainZone]) {
      acc[mainZone] = [];
    }
    acc[mainZone].push({
      name: zone,
      offset: moment.tz(zone).format('Z'),
      abbr: moment.tz(zone).zoneAbbr()
    });
    return acc;
  }, {});

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <SettingsIcon sx={{ fontSize: 40, mb: 1 }} />
        <Typography variant="h6" sx={{ fontWeight: 'normal' }}>Settings</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <SettingsBox>
              <LabelTypography>Timezone</LabelTypography>
              <Select
                fullWidth
                value={timeZone}
                onChange={(e) => setTimeZone(e.target.value)}
                variant="outlined"
                size="small"
              >
                {Object.entries(groupedTimezones).map(([group, zones]) => [
                  <ListSubheader key={group}>{group}</ListSubheader>,
                  ...zones.map((zone) => (
                    <MenuItem key={zone.name} value={zone.name}>
                      {zone.name} ({zone.offset}) {zone.abbr}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </SettingsBox>
            <SettingsBox>
              <LabelTypography>Whitelisted IPs</LabelTypography>
              {ipList.map((ip, index) => (
                <IPBox key={index}>
                  <IPTypography>{ip}</IPTypography>
                  <IconButton size="small" onClick={() => handleRemoveIP(ip)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </IPBox>
              ))}
              <Box display="flex" alignItems="center" mb={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Add IP"
                  value={newIP}
                  onChange={(e) => setNewIP(e.target.value)}
                  sx={{ marginRight: 1 }}
                />
                <AddButton variant="contained" color="primary" onClick={handleAddIP}>
                  +
                </AddButton>
              </Box>
            </SettingsBox>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button onClick={handleSaveSettings} color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SettingsModal;