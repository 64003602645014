import { Search } from "@mui/icons-material";
import { Backdrop, Box, Chip, CircularProgress, Container, InputAdornment, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Terminal } from '@xterm/xterm';
import '@xterm/xterm/css/xterm.css';
import { FitAddon } from '@xterm/addon-fit';
import { getBaseUrl, getWSUrl } from "../common";

export default function Server(props) {
    const baseUrl = getBaseUrl();
    const wsUrl = getWSUrl();

    const location = useLocation();
    const { groupId, serverId } = useParams(location.state || {});
    const [isLoading, setIsLoading] = useState(true);
    const [server, setServer] = useState(null);
    const [inputLine, setInputLine] = useState(null);
    const [path, setPath] = useState("~");

    const socket = useRef(null);
    const xtermRef = useRef(null);
    const terminalRef = useRef(null);
    const fitAddon = useRef(new FitAddon());

    const onData = (data) => {
        switch (data) {
            case '\r': // Enter key
                terminalRef.current.writeln('');
                socket.current.send(JSON.stringify({ cmd: `cd ${path} && ${inputLine}` }));
                setInputLine('');
                break;
            case '\u007F': // Backspace key
                // Do not delete the prompt
                if (terminalRef.current._core.buffer.x > (server.username + '@' + server.host + ': ' + path + ' $ ').length) {
                    terminalRef.current.write('\b \b');
                    setInputLine(inputLine.slice(0, -1));
                }
                break;
            default:
                // Print all other characters for now
                terminalRef.current.write(data);
                setInputLine(inputLine + data);
        }
    };

    useEffect(() => {
        if (location.state) setServer(location.state.server);
        else {
            // console.log('fetched');
            // (async () => {
            //     const data = (await import('../fake_data.json')).default;
            //     setServer(data.filter(gr => gr.id.toString() === groupId)[0].servers.filter(ser => {
            //         if (ser.id.toString() === serverId) {
            //             ser.createdAt = new Date(ser.createdAt);
            //         }
            //         return ser.id.toString() === serverId;
            //     })[0]);
            //     console.log(server);
            // })();
            (async () => {
                let data = {};
                try {
                    const response = await fetch(`${baseUrl}/group/${groupId}/server/get/${serverId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    data = await response.json();
                    if (data.success) {
                        setServer(data.server);
                        //setIsLoading(false);
                    } else {
                        console.error(data.message);
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.error(data.message);
                    setIsLoading(false);
                }
            })();
        }
        //  else setServer(processCommands(server));
    }, []);

    useEffect(() => {
        if (server && xtermRef.current) {
            terminalRef.current = new Terminal();
            terminalRef.current.loadAddon(fitAddon.current);
            terminalRef.current.open(xtermRef.current);
            fitAddon.current.fit();

            // Initialize WebSocket connection
            socket.current = new WebSocket(wsUrl, [localStorage.getItem('token')]);
            socket.current.onopen = () => {
                console.log('WebSocket connection established');
                socket.current.send(JSON.stringify({ start: true, server: { host: server.host, port: server.port, username: server.username, password: server.password, commands: server.commands } }));
            };

            socket.current.onclose = () => {
                console.log('WebSocket connection closed');
            };

            // Cleanup on component unmount
            return () => {
                socket.current.close();
                terminalRef.current.dispose();
            };
        }
    }, [server]);

    useEffect(() => {
        if (server && socket.current) {
            socket.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.started) {
                    terminalRef.current.write(server.username + '@' + server.host + ': ' + path + ' $ ');
                    setInputLine('');
                    setIsLoading(false);
                } else {
                    if (data.stdout !== "") {
                        const lines = data.stdout.split('\n');
                        lines.map(line => terminalRef.current.writeln(line));
                    }
                    if (data.stderr !== "") {
                        const errlines = data.stderr.split('\n');
                        errlines.map(line => terminalRef.current.writeln(line));
                    }
                    let pth = path;
                    if (data.path) {
                        setPath(data.path);
                        pth = data.path;
                    }
                    //terminalRef.current.write(data.stdout);
                    //terminalRef.current.writeln('');
                    terminalRef.current.write(server.username + '@' + server.host + ': ' + pth + ' $ ');
                }
            };
        }
    }, [server, path]);

    useEffect(() => {
        if (terminalRef.current) {
            const disposable = terminalRef.current.onData(onData);

            return () => {
                disposable.dispose();
            };
        }
    }, [inputLine, path]);

    return server !== null && <Container>
        <Box sx={{
            display: 'flex', flexDirection: {
                xs: 'column',
                sm: 'row'
            }, mb: 3, justifyContent: "space-between"
        }}>
            <TextField sx={{
                mb: {
                    xs: 3,
                    sm: 0
                }
            }} variant="outlined" type="search" placeholder="Search here..." InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }} />
            <Chip variant="outlined" label={<Box display="flex"><Typography>Status: </Typography>{server.online ?
                <Typography fontWeight="bold" sx={{ color: green["A400"] }}>Online</Typography> :
                <Typography fontWeight="bold" color="error">Offline</Typography>}</Box>} />
        </Box>
        <Box ref={xtermRef} style={{ width: '100%', height: '100%' }} />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={server === null || isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
}