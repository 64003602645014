import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadFileIcon from '@mui/icons-material/CloudUpload';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import StorageIcon from '@mui/icons-material/Storage';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Dashboard() {
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(matchesMD);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(matchesMD);
    }, [matchesMD]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} color='secondary'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {location.pathname.startsWith('/groups/') && (
                        <IconButton
                            color="inherit"
                            aria-label="go back"
                            onClick={() => navigate('/groups')}
                            edge="start"    
                            sx={{ marginRight: 2 }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {location.pathname.startsWith('/addList') ? (
                        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Upload Lists
                        </Typography>
                    ) : location.pathname.startsWith('/groups/') ? (
                        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Group Servers
                        </Typography>
                    ) : location.pathname.startsWith('/settings') ? (
                        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Settings
                        </Typography>
                    ) : (
                        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Groups List
                        </Typography>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <Box
                        component="span"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="/logo.png"
                            alt="logo"
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: theme.spacing(1),
                                display: open ? 'block' : 'none',
                            }}
                        />
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ display: open ? 'block' : 'none' }}
                        >
                            ServerDash
                        </Typography>
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {[
                        { name: 'Dashboard', component: DashboardIcon, path: '/' },
                        { name: 'Servers', component: StorageIcon, path: '/groups' },
                        { name: 'Settings', component: SettingsIcon, path: '/settings' },
                        { name: 'Upload Lists', component: UploadFileIcon, path: '/addList' },
                        { name: 'Sign out', component: ExitToAppIcon, path: '/login' }
                    ].map((item, index) => (
                        <ListItem key={item.name + "_" + index} disablePadding sx={{ display: 'block' }} onClick={() => {
                            if (item.name === 'Sign out') {
                                localStorage.removeItem("token");
                                navigate('/login');
                            } else {
                                navigate(item.path);
                            }
                        }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    backgroundColor: location.pathname === item.path ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: location.pathname === item.path ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {<item.component />}
                                </ListItemIcon>
                                <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, height: "100%" }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}