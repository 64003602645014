import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Grid } from '@mui/material';

export function Droppable(props) {
    const { isOver, setNodeRef } = useDroppable({
        id: "droppable_" + props.id,
    });
    const style = {
        //backgroundColor: isOver ? '#c7f7c7' : undefined,
    };


    return (
        <Grid ref={setNodeRef} style={style} item sm={6} md={6} lg={3}>
            {props.children}
        </Grid>
    );
}