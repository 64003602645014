import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { BorderColorOutlined, DeleteOutline, DragIndicatorOutlined } from '@mui/icons-material';
import { CardHeader, IconButton, Checkbox, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDraggable } from '@dnd-kit/core';

const ServerGroupCard = ({
    data,
    setSelectedGroup,
    handleDeleteGroupModalOpen,
    handleEditGroupModalOpen,
    reboot,
    isSelected,
    onGroupSelection
}) => {
    const navigate = useNavigate();
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: data._id,
        data: data
    });
    const hasServers = data.servers.length > 0;

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <Card sx={{
            minWidth: {
                sm: 100,
                md: 275
            },
            backgroundColor: "white"
        }}
            ref={setNodeRef} style={style}
        >
            <CardHeader
                avatar={
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={isSelected && hasServers}
                            onChange={() => hasServers && onGroupSelection(data._id)}
                            inputProps={{ 'aria-label': 'select group' }}
                            disabled={!hasServers}
                        />
                        <IconButton aria-label="Server Group" {...listeners} {...attributes}>
                            <DragIndicatorOutlined />
                        </IconButton>
                    </Box>
                }
            />
            <CardContent sx={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                setSelectedGroup(data);
                navigate(`/groups/${data._id}`, { state: data });
            }}>
                <Typography variant="h5" component="div">
                    {data.name}
                </Typography>
                <Typography color="text.secondary">
                    {"# servers: " + data.servers.length}
                </Typography>
                <Typography color="text.secondary">
                    {"# servers (online): " + data.servers.filter(server => server.online).length}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {"Creation date: " + new Date(data.createdAt).toLocaleDateString()}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
                <IconButton aria-label="Remove" onClick={(e) => {
                    e.stopPropagation();
                    setSelectedGroup(data);
                    handleDeleteGroupModalOpen();
                }}>
                    <DeleteOutline color='error' />
                </IconButton>
                <IconButton aria-label="Edit" onClick={(e) => {
                    e.stopPropagation();
                    setSelectedGroup(data);
                    handleEditGroupModalOpen();
                }}>
                    <BorderColorOutlined />
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default ServerGroupCard;