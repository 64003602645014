import React, { createContext, useState, useContext } from 'react';

const SelectedServersContext = createContext();

export const SelectedServersProvider = ({ children }) => {
  const [selectedServers, setSelectedServers] = useState(new Set());

  return (
    <SelectedServersContext.Provider value={{ selectedServers, setSelectedServers }}>
      {children}
    </SelectedServersContext.Provider>
  );
};

export const useSelectedServers = () => useContext(SelectedServersContext);