import { AddCircleOutline, Search, Refresh } from "@mui/icons-material";
import { Alert, Backdrop, Box, Button, CircularProgress, Grid, InputAdornment, Snackbar, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CreateGroupModal from "./CreateGroupModal";
import DeleteGroupModal from "./DeleteGroupModal";
import EditGroupModal from "./EditGroupModal";
import ServerGroupCard from "./ServerGroupCard";
import { Droppable } from "./Droppable";
import { useSelectedServers } from '../SelectedServersContext';
import { DndContext } from "@dnd-kit/core";
import { getBaseUrl, getWSUrl } from "../common";

export default function Groups(props) {
    const [rebootingServers, setRebootingServers] = useState(new Set());
    const baseUrl = getBaseUrl();
    const wsUrl = getWSUrl();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [serverGroups, setServerGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
    const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
    const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const { selectedServers, setSelectedServers } = useSelectedServers();
    const socket = useRef(null);
    const [selectedGroups, setSelectedGroups] = useState(() => {
        const saved = localStorage.getItem('selectedGroups');
        return saved ? new Set(JSON.parse(saved)) : new Set();
    });

    useEffect(() => {
        localStorage.setItem('selectedGroups', JSON.stringify([...selectedGroups]));
    }, [selectedGroups]);

    const handleCreateGroupModalOpen = () => setOpenCreateGroupModal(true);
    const handleCreateGroupModalClose = () => setOpenCreateGroupModal(false);

    const handleDeleteGroupModalOpen = () => setOpenDeleteGroupModal(true);
    const handleDeleteGroupModalClose = () => setOpenDeleteGroupModal(false);

    const handleEditGroupModalOpen = () => setOpenEditGroupModal(true);
    const handleEditGroupModalClose = () => setOpenEditGroupModal(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleRestartSelectedServers = () => {
        if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
            console.error("WebSocket connection not established.");
            return;
        }

        const serversToReboot = new Set(selectedServers);
        setRebootingServers(serversToReboot);

        setOpen(true);
        setMessage(`Restart initiated for ${selectedServers.size} selected servers.`);

        // Optional: Clear the rebooting status after a set time (e.g., 30 seconds)
        setTimeout(() => {
            setRebootingServers(new Set());
        }, 30000);
    };
    const handleGroupSelection = (groupId) => {
        setSelectedServers(prev => {
            const newSet = new Set(prev);
            const group = serverGroups.find(g => g._id === groupId);
            if (group) {
                const allServersInGroupSelected = group.servers.every(server => newSet.has(server._id));
                if (allServersInGroupSelected) {
                    // If all servers in the group are selected, remove them all
                    group.servers.forEach(server => newSet.delete(server._id));
                } else {
                    // If not all servers are selected, add all servers from the group
                    group.servers.forEach(server => newSet.add(server._id));
                }
            }
            return newSet;
        });
    };

    useEffect(() => {
        const allServers = new Set(serverGroups.flatMap(group => group.servers.map(server => server._id)));
        setSelectAll(selectedServers.size === allServers.size && selectedServers.size > 0);
    }, [selectedServers, serverGroups]);

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (checked) {
            const allServers = new Set(serverGroups.flatMap(group => group.servers.map(server => server._id)));
            setSelectedServers(allServers);
        } else {
            setSelectedServers(new Set());
        }
    };
    const handleRestartSelectedGroups = () => {
        if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
          console.error("WebSocket connection not established.");
          return;
        }
      
        const serversToReboot = new Set(selectedServers);
        setRebootingServers(serversToReboot);
      
        serverGroups.forEach(group => {
          group.servers.forEach(server => {
            if (selectedServers.has(server._id)) {
              socket.current.send(JSON.stringify({ reboot: server }));
            }
          });
        });
      
        setSelectAll(false);
        setSelectedServers(new Set());
        setOpen(true);
        setMessage(`Restart initiated for ${selectedServers.size} selected servers.`);
      
        setTimeout(() => {
          setRebootingServers(new Set());
        }, 3000);
      };

    async function handleDragEnd(event) {
        const { over } = event;
        const arr = over.id.split('_');
        let selectedArrId = -1;

        for (let i = 0; i < serverGroups.length; i++) {
            if (serverGroups[i]._id === selectedGroup._id) selectedArrId = i;
        }

        let data = {};
        try {
            const response = await fetch(`${baseUrl}/group/order`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    id1: selectedGroup._id,
                    id2: serverGroups[parseInt(arr[arr.length - 1])]._id,
                    order1: parseInt(arr[arr.length - 1]),
                    order2: selectedArrId
                })
            });
            data = await response.json();
            if (data.success) {
                console.log("success");
                setServerGroups(data.serverGroups);
                setIsLoading(false);
            } else {
                console.error(data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(data.message);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            let data = {};
            try {
                const response = await fetch(baseUrl + '/group/get', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                data = await response.json();
                if (data.success) {
                    setServerGroups(data.serverGroups);
                    setIsLoading(false);
                } else {
                    console.error(data.message);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(data.message);
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (serverGroups.length > 0) {
            socket.current = new WebSocket(wsUrl, [localStorage.getItem('token')]);

            socket.current.onopen = () => {
                console.log('WebSocket connection established');
                const allServers = serverGroups.flatMap(group => group.servers);
                socket.current.send(JSON.stringify({ ping: allServers }));
            };

            socket.current.onclose = () => {
                console.log('WebSocket connection closed');
            };

            socket.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(data);
                if (data.reboot_err) {
                    setOpen(true);
                    setMessage(JSON.stringify(data.reboot_err));
                }
                if (data.pong && Object.keys(data.pong).length > 0) {
                    setServerGroups(prevGroups => prevGroups.map(group => ({
                        ...group,
                        servers: group.servers.map(server => ({
                            ...server,
                            online: data.pong[server._id]
                        }))
                    })));
                }
                setTimeout(() => {
                    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                        const allServers = serverGroups.flatMap(group => group.servers);
                        socket.current.send(JSON.stringify({ ping: allServers }));
                    }
                }, 3000);
            };
        }

        return () => {
            if (socket.current) socket.current.close();
        };
    }, [serverGroups]);

    return <div>
        <Box sx={{
            display: 'flex', flexDirection: {
                xs: 'column',
                sm: 'row'
            }, mb: 3, justifyContent: "space-between"
        }}>
            <TextField sx={{
                mb: {
                    xs: 3,
                    sm: 0
                }
            }} variant="outlined" type="search" placeholder="Search here..." InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }} />
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            indeterminate={selectedServers.size > 0 && selectedServers.size < serverGroups.flatMap(group => group.servers).length}
                            onChange={handleSelectAll}
                        />
                    }
                    label="Select All"
                />
                <Button
                    startIcon={<Refresh />}
                    onClick={handleRestartSelectedGroups}
                    variant='contained'
                    size='large'
                    color='primary'
                    disabled={rebootingServers.size > 0}
                >
                    Mass reboot ({selectedServers.size})
                </Button>
                <Button startIcon={<AddCircleOutline color='success' />} onClick={handleCreateGroupModalOpen} variant='contained' size='large' color='secondary'>Create new group</Button>
            </Box>
        </Box>

        <Grid container spacing={2} sx={{
            display: {
                xs: "inherit",
                sm: "flex",
            }
        }}>
            <DndContext onDragEnd={handleDragEnd} onDragStart={(e) => setSelectedGroup(e.active.data.current)}>
                {serverGroups.map((card, i) => (
                    <Droppable key={card.name + "_" + i} id={card.name + "_" + i}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ServerGroupCard
                                data={card}
                                id={card.name + "_" + i}
                                setSelectedGroup={setSelectedGroup}
                                handleDeleteGroupModalOpen={handleDeleteGroupModalOpen}
                                handleEditGroupModalOpen={handleEditGroupModalOpen}
                                reboot={() => {
                                    setOpen(true);
                                    setMessage(`Restart initiated for all servers in group ${card.name}`);
                                    card.servers.forEach(server => {
                                        socket.current.send(JSON.stringify({ reboot: server }));
                                    });
                                }}
                                isSelected={card.servers.every(server => selectedServers.has(server._id))}
                                onGroupSelection={() => handleGroupSelection(card._id)}
                            />
                        </Box>
                    </Droppable>
                ))}
            </DndContext>
        </Grid>
        <CreateGroupModal open={openCreateGroupModal} order={serverGroups.length} setIsLoading={setIsLoading} create={(gr) => {
            setServerGroups([...serverGroups, gr]);
            setOpen(true);
            setMessage(`Server Group ${gr.name} created`);
        }} onClose={handleCreateGroupModalClose} />
        <DeleteGroupModal open={openDeleteGroupModal} setIsLoading={setIsLoading} group={selectedGroup} delete={() => {
            setServerGroups(serverGroups.filter(serverGroup => serverGroup._id !== selectedGroup._id));
            setOpen(true);
            setMessage(`Server Group ${selectedGroup.name} deleted`);
        }} onClose={handleDeleteGroupModalClose} />
        <EditGroupModal open={openEditGroupModal} setIsLoading={setIsLoading} group={selectedGroup} edit={(gr) => {
            setServerGroups(serverGroups.map(serverGroup => serverGroup._id === selectedGroup._id ? gr : serverGroup));
            setOpen(true);
            setMessage(`Server Group ${selectedGroup.name} edited`);
        }} onClose={handleEditGroupModalClose} />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    </div>
}