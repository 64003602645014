import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { getBaseUrl } from '../common';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(1),
    width: '100%',
    maxWidth: '550px',
    height: '90vh',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1.5, 2),
    maxHeight: 'calc(85vh - 56px)',
    overflowY: 'auto',
  },
}));

const baseUrl = getBaseUrl();

const InfoBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  maxHeight: '33vh',
  overflowY: 'auto',
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.99rem',
  marginBottom: theme.spacing(0.5),
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.99rem',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  display: 'block',
  padding: theme.spacing(0.25, 0),
  fontSize: '0.99rem',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: theme.palette.action.hover,
  },
}));

const CircleIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '2px solid black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  marginBottom: theme.spacing(0.5),
}));

const ViewURLsModal = ({ open, onClose, fileData }) => {
  if (!fileData) return null;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <CircleIcon>
          <Typography variant="h5" component="span" sx={{ fontWeight: 'bold' }}>!</Typography>
        </CircleIcon>
        <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>View URLs</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <InfoBox>
          <LabelTypography>File Name</LabelTypography>
          <ValueTypography>{fileData.fileName}</ValueTypography>
        </InfoBox>
        <InfoBox>
          <LabelTypography>Date Uploaded</LabelTypography>
          <ValueTypography>{fileData.dateUploaded}</ValueTypography>
        </InfoBox>
        <InfoBox>
          <LabelTypography>Date Start</LabelTypography>
          <ValueTypography>{fileData.dateStart}</ValueTypography>
        </InfoBox>
        <InfoBox>
          <LabelTypography>Date End</LabelTypography>
          <ValueTypography>{fileData.dateEnd}</ValueTypography>
        </InfoBox>
        <InfoBox>
          <LabelTypography>URLs (Date-Splitted)</LabelTypography>
          {fileData.urlsSplitted.map((url, index) => (
            <StyledLink target="_blank" to={`${baseUrl}/list/${url.split('/')[url.split('/').length - 1]}`} key={index}>
              {url}
            </StyledLink>
          ))}
        </InfoBox>
        <InfoBox>
          <LabelTypography>URLs (Totals)</LabelTypography>
          {fileData.urlsTotals.map((url, index) => (
            <StyledLink target="_blank" to={`${baseUrl}/list/${url.split('/')[url.split('/').length - 1]}`} key={index}>
              {url}
            </StyledLink>
          ))}
        </InfoBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default ViewURLsModal;