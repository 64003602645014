import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, IconButton, InputAdornment, Checkbox, FormControlLabel, Snackbar, Alert, Paper } from '@mui/material';
import { Box } from '@mui/system';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { getBaseUrl } from '../common';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #d5d5d5;
`;

const StyledPaper = styled(Paper)(({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    padding: 40px;
    width: 100%;
  }
  padding: 40px;
  width: 500px;
`);


function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const baseUrl = getBaseUrl();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
          const response = await fetch(baseUrl + '/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password }),
          });
          const data = await response.json();
          if (data.pinId) {
            navigate('/otp', { 
              state: { 
                username: username, 
                phoneNumber: data.phoneNumber,
                pinId: data.pinId // Pass the pinId here
              } 
            });
            setMessage(data.message);
            setSeverity('success');
          } else {
            setMessage(data.message);
            setSeverity('error');
          }
        } catch (error) {
          setMessage('An error occurred');
          setSeverity('error');
        }
        setOpen(true);
      };
      
    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    
    return (
        <StyledBox>
            <StyledPaper elevation={6}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Grid container alignItems="center" justifyContent="center">
                            <img src="/logo.png" alt="logo" style={{ width: 50, height: 50, marginRight: 20 }} />
                            <Typography variant="h4" component="h1">
                                ServerDash
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography component="h2" variant="h5" align="center">
                            Log In to your account
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 1.5, mb: 2, width: '100%' }}>
                        <form onSubmit={handleLogin}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        type="email"
                                        placeholder="Email"
                                        fullWidth
                                        name="username"
                                        variant="outlined"
                                        required
                                        autoFocus
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Password"
                                        fullWidth
                                        name="password"
                                        variant="outlined"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox name="checkedC" checked={keepMeLoggedIn} onChange={(e) => setKeepMeLoggedIn(e.target.checked)} />}
                                        label="Keep me signed in"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                    >
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </StyledPaper>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </StyledBox>
    );
}

export default Login;