import { BorderColorOutlined, HighlightOffRounded } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Modal, Snackbar, TextField, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { getBaseUrl } from "../common";

export default function EditServerModal(props) {
    const baseUrl = getBaseUrl();

    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState("");

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [usernameHelperText, setUsernameHelperText] = useState("");

    const [hostAddress, setHostAddress] = useState("");
    const [hostAddressError, setHostAddressError] = useState(false);
    const [hostAddressHelperText, setHostAddressHelperText] = useState("");

    const [port, setPort] = useState("");
    const [portError, setPortError] = useState(false);
    const [portHelperText, setPortHelperText] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordHelperText, setPasswordHelperText] = useState("");

    const [commands, setCommands] = useState([]);
    const [command, setCommand] = useState("");
    const [commandError, setCommandError] = useState(false);
    const [commandHelperText, setCommandHelperText] = useState("");

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [serverErrorMessgae, setServerErrorMessgae] = useState("");

    const commandField = useRef();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        p: 1,
        pt: 0
    };

    const theme = useTheme();

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    useEffect(() => {
        if (props.server._id !== undefined) {
            console.log(props.server);
            setName(props.server.name);
            setUsername(props.server.username);
            setHostAddress(props.server.host);
            setPort(props.server.port);
            setPassword(props.server.password);
            setCommands(props.server.commands);
            setOpen(props.open);
        }
    }, [props.server, props.open]);

    return <Modal
        open={open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card sx={style}>
            <Snackbar open={snackBarOpen} anchorOrigin={{ vertical: "top", horizontal: "left" }} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity="error" sx={{ width: '100%' }}>
                    {serverErrorMessgae}
                </Alert>
            </Snackbar>
            <CardHeader
                sx={{ pb: 0, mb: 0 }}
                avatar={<Box
                    sx={{ height: theme.spacing(0), width: theme.spacing(4) }}
                />}
                title={<Typography textAlign="center">
                    <BorderColorOutlined sx={{ color: grey[900], fontSize: 150 }} />
                </Typography>}
                action={
                    <IconButton onClick={props.onClose}>
                        <HighlightOffRounded sx={{ color: grey[500], fontSize: 40 }} />
                    </IconButton>
                }
            />
            <CardContent sx={{ px: 5, py: 0 }}>
                <Typography variant="h5" textAlign="center">
                    <b>Edit Server</b>
                </Typography>
                <TextField
                    size="small"
                    sx={{ my: 2 }}
                    type="text"
                    fullWidth
                    label="Name"
                    placeholder="Enter Name..."
                    variant="outlined"
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                    onFocus={() => {
                        setNameError(false);
                        setNameHelperText("");
                    }}
                    error={nameError}
                    helperText={nameHelperText}
                />
                <TextField
                    size="small"
                    sx={{ mb: 2 }}
                    type="text"
                    fullWidth
                    label="Username"
                    placeholder="Enter Username..."
                    variant="outlined"
                    defaultValue={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onFocus={() => {
                        setUsernameError(false);
                        setUsernameHelperText("");
                    }}
                    error={usernameError}
                    helperText={usernameHelperText}
                />
                <TextField
                    size="small"
                    sx={{ mb: 2 }}
                    type="text"
                    fullWidth
                    label="Host Address"
                    placeholder="Enter Host Address..."
                    variant="outlined"
                    defaultValue={hostAddress}
                    onChange={(e) => setHostAddress(e.target.value)}
                    onFocus={() => {
                        setHostAddressError(false);
                        setHostAddressHelperText("");
                    }}
                    error={hostAddressError}
                    helperText={hostAddressHelperText}
                />
                <TextField
                    size="small"
                    sx={{ mb: 2 }}
                    type="text"
                    fullWidth
                    label="Port (Default = 22)"
                    defaultValue={port}
                    placeholder="Enter Port..."
                    variant="outlined"
                    onChange={(e) => setPort(e.target.value)}
                    onFocus={() => {
                        setPortError(false);
                        setPortHelperText("");
                    }}
                    error={portError}
                    helperText={portHelperText}
                />
                <TextField
                    size="small"
                    sx={{ mb: 1 }}
                    type="password"
                    fullWidth
                    label="Password"
                    placeholder="Enter Password..."
                    variant="outlined"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => {
                        setPasswordError(false);
                        setPasswordHelperText("");
                    }}
                    error={passwordError}
                    helperText={passwordHelperText}
                />
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Commands to execute when starting
                </Typography>
                {commands.map((command, index) => <Box sx={{ mb: 2 }} key={index}><Chip label={command} onDelete={() => setCommands(commands.filter((com, i) => i !== index))} /></Box>)}
                <Box display="flex" sx={{ mb: 2 }}>
                    <TextField
                        inputRef={commandField}
                        size="small"
                        type="text"
                        fullWidth
                        label="Command"
                        placeholder="Enter Command..."
                        variant="outlined"
                        onChange={(e) => setCommand(e.target.value)}
                        onFocus={() => {
                            setCommandError(false);
                            setCommandHelperText("");
                        }}
                        error={commandError}
                        helperText={commandHelperText}
                    />
                    <Button variant="contained" color="primary" onClick={() => {
                        if (command === "") {
                            setCommandHelperText("Command is required");
                            setCommandError(true);
                        } else {
                            setCommands([...commands, command]);
                            commandField.current.value = "";
                        }
                    }}>Add</Button>
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Button onClick={async () => {
                    if (name === "") {
                        setNameHelperText("Server Name is required");
                        setNameError(true);
                    } else if (username === "") {
                        setUsernameHelperText("Username is required");
                        setUsernameError(true);
                    } else if (hostAddress === "") {
                        setHostAddressHelperText("Host Address is required");
                        setHostAddressError(true);
                    } else if (port === "") {
                        setPortHelperText("Port is required");
                        setPortError(true);
                    } else if (password === "") {
                        setPasswordHelperText("Password is required");
                        setPasswordError(true);
                    } else {
                        let data = {};
                        try {
                            const response = await fetch(`${baseUrl}/group/${props.groupId}/server/edit/${props.server._id}`, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                },
                                body: JSON.stringify({
                                    name: name,
                                    username: username,
                                    host: hostAddress,
                                    port: port,
                                    password: password,
                                    commands: commands
                                })
                            });
                            data = await response.json();
                            if (data.success) {
                                props.edit(data.serverGroup.servers, name);
                                props.setIsLoading(false);
                                props.onClose();
                            } else {
                                console.error(data.message);
                                props.setIsLoading(false);
                                setServerErrorMessgae(data.message);
                                setSnackBarOpen(true);
                            }
                        } catch (error) {
                            console.error(data.message);
                            props.setIsLoading(false);
                            setServerErrorMessgae(data.message);
                            setSnackBarOpen(true);
                        }
                    }
                }} variant='outlined' sx={{ color: grey[500], mb: 4 }}>
                    Confirm
                </Button>
            </CardActions>
        </Card>
    </Modal>
}