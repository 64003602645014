import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import Login from './components/login';
import Dashboard from './components/dashboard';
import { amber, green, red } from '@mui/material/colors';
import Servers from './components/Servers';
import Groups from './components/Groups';
import Server from './components/Server';
import Settings from './components/Settings';
import AddList from './components/AddList';
import { SelectedServersProvider } from './SelectedServersContext';
import OtpGeneration from './components/OtpGeneration';
import { getBaseUrl } from './common';

const AppRoutes = () => {
  const baseUrl = getBaseUrl();

  const navigate = useNavigate();
  const location = useLocation();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`${baseUrl}/isloggedin`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log(location.pathname);
            //setIsAuthenticated(true);
            navigate(location.pathname);
          } else {
            //setIsAuthenticated(false);
            navigate('/login');
          }
          setIsAuthChecked(true);
        })
        .catch(error => {
          console.error('Error:', error);
          //setIsAuthenticated(false);
          navigate('/login');
          setIsAuthChecked(true);
        });
    } else {
      //setIsAuthenticated(false);
      navigate('/login');
      setIsAuthChecked(true);
    }
  }, []);

  if (!isAuthChecked) {
    return <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!isAuthChecked}
    >
      <CircularProgress color="inherit" />
    </Backdrop>; // replace with a loading spinner
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/otp" element={<OtpGeneration />} />

      <Route element={<SelectedServersProvider>
        <Dashboard />
      </SelectedServersProvider>}>
        <Route  path="groups" element={<Groups />} />
        <Route  path="settings" element={<Settings />} />
        <Route  path="/" element={<Groups />} />
        <Route path="groups/:groupId" element={<Servers />} />
        <Route path="addList" element={<AddList />} />
        <Route path="groups/:groupId/server/:serverId" element={<Server />} />
      </Route>
    </Routes>
  );
};

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#5D5FEF",
        main: "#5D5FEF"
      },
      secondary: {
        light: "#FFFFFF",
        main: "#FFFFFF"
      },
      success: {
        light: green["A400"],
        main: green["A400"]
      },
      error: {
        light: red[600],
        main: red[600]
      },
      warning: {
        light: amber['A400'],
        main: amber['A400']
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
  );
}

export default App;