import { AddCircleOutline, Search, Refresh } from "@mui/icons-material";
import { Alert, Backdrop, Box, Button, CircularProgress, Grid, InputAdornment, Snackbar, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ServerCard from "./ServerCard";
import CreateServerModal from "./CreateServerModal";
import DeleteServerModal from "./DeleteServerModal";
import { useSelectedServers } from '../SelectedServersContext';
import EditServerModal from "./EditServerModal";
import { DndContext } from "@dnd-kit/core";
import { Droppable } from "./Droppable";
import { getBaseUrl, getWSUrl } from "../common";

export default function Servers(props) {
    const wsUrl = getWSUrl();
    const baseUrl = getBaseUrl();

    const location = useLocation();
    const { groupId } = useParams(location.state || {});

    const [servers, setServers] = useState(location.state && location.state.servers.length > 0 ? location.state.servers.map(server => {
        server.createdAt = new Date(server.createdAt);
        return server;
    }).sort((a, b) => a.order - b.order) : []);
    const [selected, setSelected] = useState({
        "name": "",
        "username": "",
        "password": "",
        "port": 22,
        "host": "",
        "commands": [],
        "createdAt": new Date()
    });
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const socket = useRef(null);
    const pings = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [openCreateServerModal, setOpenCreateServerModal] = useState(false);
    const [openDeleteServerModal, setOpenDeleteServerModal] = useState(false);
    const [openEditServerModal, setOpenEditServerModal] = useState(false);
    const [showWSWarning, setShowWSWarning] = useState(false);
    const warningTimerRef = useRef(null);
    const [rebootingServers, setRebootingServers] = useState(new Set());
    const { selectedServers, setSelectedServers } = useSelectedServers();
    const [selectAll, setSelectAll] = useState(false);

    const handleCreateServerModalOpen = () => setOpenCreateServerModal(true);
    const handleCreateServerModalClose = () => setOpenCreateServerModal(false);

    const handleDeleteServerModalOpen = () => setOpenDeleteServerModal(true);
    const handleDeleteServerModalClose = () => setOpenDeleteServerModal(false);

    const handleEditServerModalOpen = () => setOpenEditServerModal(true);
    const handleEditServerModalClose = () => setOpenEditServerModal(false);
    useEffect(() => {
        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, []);
    useEffect(() => {
        if (servers.length > 0) {
            if (socket.current) {
                socket.current.close();
            }
            setShowWSWarning(false);
            establishWebSocketConnection();
        } else {
            if (socket.current) {
                socket.current.close();
            }
        }
    }, [servers]);

    const handleServerSelection = (serverId) => {
        setSelectedServers(prev => {
            const newSet = new Set(prev);
            if (newSet.has(serverId)) {
                newSet.delete(serverId);
            } else {
                newSet.add(serverId);
            }
            return newSet;
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        const allServers = new Set(servers.map(server => server._id));
        setSelectAll(selectedServers.size === allServers.size && selectedServers.size > 0);
    }, [selectedServers, servers]);

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (checked) {
            setSelectedServers(new Set(servers.map(server => server._id)));
        } else {
            setSelectedServers(new Set());
        }
    };
    

    const handleRestartSelectedServers = () => {
        if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
          console.error("WebSocket connection not established.");
          return;
        }
      
        const serversToReboot = new Set(selectedServers);
        setRebootingServers(serversToReboot);
      
        servers.forEach(server => {
          if (selectedServers.has(server._id)) {
            socket.current.send(JSON.stringify({ reboot: server }));
          }
        });
      
        setOpen(true);
        setMessage(`Restart initiated for ${selectedServers.size} selected servers.`);
        setSelectAll(false);
        setSelectedServers(new Set());
        setTimeout(() => {
          setRebootingServers(new Set());
        }, 3000);
      };

    async function handleDragEnd(event) {
        const { over } = event;
        const arr = over.id.split('_');
        let selectedArrId = -1;

        for (let i = 0; i < servers.length; i++) {
            if (servers[i]._id === selected._id) selectedArrId = i;
        }

        let data = {};
        try {
            const response = await fetch(`${baseUrl}/group/${groupId}/server/order`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    id1: selected._id,
                    id2: servers[parseInt(arr[arr.length - 1])]._id,
                    order1: parseInt(arr[arr.length - 1]),
                    order2: selectedArrId
                })
            });
            data = await response.json();
            if (data.success) {
                console.log("success");
                setServers(data.servers);
                setIsLoading(false);
            } else {
                console.error(data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(data.message);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!location.state) {
            setIsLoading(true);
            (async () => {
                let data = {};
                try {
                    const response = await fetch(`${baseUrl}/group/${groupId}/server/get`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    data = await response.json();
                    if (data.success) {
                        setServers(data.servers);
                        setIsLoading(false);
                    } else {
                        console.error(data.message);
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.error(data.message);
                    setIsLoading(false);
                }
            })();
        }
    }, [location.state, groupId]);

    const establishWebSocketConnection = () => {
        socket.current = new WebSocket(wsUrl, [localStorage.getItem('token')]);

        socket.current.onopen = () => {
            console.log('WebSocket connection established');
            setShowWSWarning(false);
            clearTimeout(warningTimerRef.current);
            socket.current.send(JSON.stringify({ ping: servers }));
        };

        socket.current.onclose = () => {
            console.log('WebSocket connection closed');
            startWarningTimer();
        };

        const startWarningTimer = () => {
            clearTimeout(warningTimerRef.current);
            warningTimerRef.current = setTimeout(() => {
                setShowWSWarning(true);
            }, 7000);
        };

        socket.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log(data);
            if (data.reboot_err) {
                setOpen(true);
                setMessage(JSON.stringify(data.reboot_err));
            }
            if (data.pong && Object.keys(data.pong).length > 0) {
                setServers(prevServers => {
                    const updatedServers = prevServers.filter(server => data.pong.hasOwnProperty(server._id));
                    updatedServers.forEach(server => {
                        server.online = data.pong[server._id];
                    });
                    return updatedServers;
                });
            }
            setTimeout(() => {
                if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                    socket.current.send(JSON.stringify({ ping: servers }));
                }
            }, 3000);
        };
    };

    useEffect(() => {
        setLoadedOnce(true);
        if (loadedOnce) {
            return () => {
                if (socket.current) socket.current.close();
                clearTimeout(warningTimerRef.current);
            }
        }
    }, [loadedOnce]);

    return (
        <div>
            <Box sx={{
                display: 'flex', flexDirection: {
                    xs: 'column',
                    sm: 'row'
                }, mb: 3, justifyContent: "space-between"
            }}>
                <TextField sx={{
                    mb: {
                        xs: 3,
                        sm: 0
                    }
                }} variant="outlined" type="search" placeholder="Search here..." InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }} />
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <FormControlLabel
    control={
        <Checkbox
            checked={selectAll}
            indeterminate={selectedServers.size > 0 && selectedServers.size < servers.length}
            onChange={handleSelectAll}
        />
    }
    label="Select All"
/>

                    <Button
                        startIcon={<Refresh />}
                        onClick={handleRestartSelectedServers}
                        variant='contained'
                        size='large'
                        color='primary'
                        disabled={rebootingServers.size > 0}
                    >
                        Mass reboot ({selectedServers.size})
                    </Button>
                    <Button startIcon={<AddCircleOutline color='success' />} onClick={handleCreateServerModalOpen} variant='contained' size='large' color='secondary'>Create new server</Button>
                </Box>
            </Box>

            <Grid container spacing={2} sx={{
                display: {
                    xs: "inherit",
                    sm: "flex",
                }
            }}>
                <DndContext onDragEnd={handleDragEnd} onDragStart={(e) => setSelected(e.active.data.current)}>
                    {servers.map((card, i) => (
                        <Droppable key={card.name + "_" + i} id={card.name + "_" + i}>
                            <ServerCard
                                data={card}
                                id={card.name + "_" + i}
                                setSelected={setSelected}
                                handleDeleteServerModalOpen={handleDeleteServerModalOpen}
                                handleEditServerModalOpen={handleEditServerModalOpen}
                                groupId={groupId}
                                isRebooting={rebootingServers.has(card._id)}
                                reboot={() => {
                                    setRebootingServers(prev => new Set(prev).add(card._id));
                                    setOpen(true);
                                    setMessage(`Restart initiated for server ${card.name}`);
                                    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                                        socket.current.send(JSON.stringify({ reboot: card }));
                                    }
                                    setTimeout(() => {
                                        setRebootingServers(prev => {
                                            const newSet = new Set(prev);
                                            newSet.delete(card._id);
                                            return newSet;
                                        });
                                    }, 30000);
                                }}
                                isSelected={selectedServers.has(card._id)}
                                onServerSelection={handleServerSelection}
                            />
                        </Droppable>
                    ))}
                </DndContext>
            </Grid>
            <CreateServerModal
                open={openCreateServerModal}
                order={servers.length}
                setIsLoading={setIsLoading}
                groupId={groupId}
                create={(sers, name) => {
                    setServers(sers);
                    pings.current = sers.map(server => server);
                    setOpen(true);
                    setMessage(`Server ${name} created`);
                }}
                onClose={handleCreateServerModalClose}
            />
            <DeleteServerModal
                open={openDeleteServerModal}
                setIsLoading={setIsLoading}
                groupId={groupId}
                server={selected}
                delete={() => {
                    setServers(prevServers => {
                        const newServers = prevServers.filter(server => server._id !== selected._id);
                        if (newServers.length === 0) {
                            if (socket.current) {
                                socket.current.close();
                            }
                            // Handle empty server list (e.g., show a message, disable certain features)
                        }
                        return newServers;
                    });
                    setOpen(true);
                    setMessage(`Server ${selected.name} deleted`);
                }}
                onClose={handleDeleteServerModalClose}
            />
            <EditServerModal
                open={openEditServerModal}
                setIsLoading={setIsLoading}
                groupId={groupId}
                server={selected}
                edit={(sers, name) => {
                    setServers(sers);
                    setOpen(true);
                    setMessage(`Server ${name} edited`);
                }}
                onClose={handleEditServerModalClose}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}