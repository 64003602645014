import { BorderColorOutlined, HighlightOffRounded } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardHeader, IconButton, Modal, TextField, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { getBaseUrl } from "../common";

export default function EditGroupModal(props) {
    const baseUrl = getBaseUrl();

    const [name, setName] = useState("");
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        p: 1,
        pt: 0
    };

    const theme = useTheme();

    return <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card sx={style}>
            <CardHeader
                sx={{ pb: 0, mb: 0 }}
                avatar={<Box
                    sx={{ height: theme.spacing(0), width: theme.spacing(4) }}
                />}
                title={<Typography textAlign="center">
                    <BorderColorOutlined sx={{ color: grey[900], fontSize: 150 }} />
                </Typography>}
                action={
                    <IconButton onClick={props.onClose}>
                        <HighlightOffRounded sx={{ color: grey[500], fontSize: 40 }} />
                    </IconButton>
                }
            />
            <CardContent sx={{ px: 5, py: 0 }}>
                <Typography variant="h5" textAlign="center">
                    <b>Edit Group</b>
                </Typography>
                {/* <Typography variant="h6">
                    Group Name
                </Typography> */}
                <TextField
                    size="small"
                    sx={{ my: 2 }}
                    type="text"
                    fullWidth
                    label="Group Name"
                    placeholder="Enter Group Name..."
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                    onFocus={() => {
                        setError(false);
                        setHelperText("");
                    }}
                    error={error}
                    helperText={helperText}
                />
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Button onClick={async () => {
                    if (name === "") {
                        setHelperText("Group Name is required");
                        setError(true);
                    } else {
                        let data = {};
                        try {
                            const response = await fetch(`${baseUrl}/group/edit/${props.group._id}`, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                },
                                body: JSON.stringify({
                                    name: name
                                })
                            });
                            data = await response.json();
                            if (data.success) {
                                props.edit(data.serverGroup);
                                props.setIsLoading(false);
                                props.onClose();
                            } else {
                                console.error(data.message);
                                props.setIsLoading(false);
                                setHelperText(data.message);
                                setError(true);
                            }
                        } catch (error) {
                            console.error(data.message);
                            props.setIsLoading(false);
                            setHelperText(data.message);
                            setError(true);
                        }
                    }
                }} variant='outlined' sx={{ color: grey[500], mb: 4 }}>
                    Confirm
                </Button>
            </CardActions>
        </Card>
    </Modal>
}