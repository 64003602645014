import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  IconButton,
  Grid,
  Paper
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const Calendar = ({ onDateRangeChange, initialStartDate, initialEndDate }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
      if (!isInitialized && initialStartDate) {
        const initialDate = new Date(initialStartDate);
        setCurrentDate(initialDate);
        setStartDate(initialDate);
        if (initialEndDate) {
          setEndDate(new Date(initialEndDate));
        }
        setIsInitialized(true);
      }
    }, [initialStartDate, initialEndDate, isInitialized]);

    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    useEffect(() => {
      if (startDate && endDate) {
        onDateRangeChange({ startDate, endDate });
      }
    }, [startDate, endDate, onDateRangeChange]);

    const prevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const nextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const handleDateClick = (date) => {
      if (!startDate || (startDate && endDate)) {
        setStartDate(date);
        setEndDate(null);
      } else if (date < startDate) {
        setStartDate(date);
        setEndDate(startDate);
      } else {
        setEndDate(date);
      }
    };

    const renderCalendarDays = () => {
      const days = [];
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      // Add empty cells for days before the first day of the month
      for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(<Grid item key={`empty-${i}`} xs={1.7}></Grid>);
      }

      // Add cells for each day of the month
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
        const isStartDate = startDate && startDate.getTime() === date.getTime();
        const isEndDate = endDate && endDate.getTime() === date.getTime();
        const isInRange = startDate && endDate && date >= startDate && date <= endDate;

        days.push(
          <Grid item key={i} xs={1.7}>
            <Paper 
              elevation={0} 
              onClick={() => handleDateClick(date)}
              sx={{
                height: 40,
                width: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isStartDate || isEndDate ? '#1976d2' : isInRange ? '#e3f2fd' : 'transparent',
                color: isStartDate || isEndDate ? 'white' : 'inherit',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
            >
              {i}
            </Paper>
          </Grid>
        );
      }

      return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <IconButton onClick={prevMonth}><ChevronLeft /></IconButton>
            <Typography variant="h6">{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</Typography>
            <IconButton onClick={nextMonth}><ChevronRight /></IconButton>
          </Box>
          <Grid container spacing={1}>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <Grid item key={day} xs={1.7}>
                <Typography variant="body2" align="center">{day}</Typography>
              </Grid>
            ))}
            {days}
          </Grid>
        </>
      );
    };

    return (
      <Box sx={{ width: 300, p: 2 }}>
        {renderCalendarDays()}
      </Box>
    );
};

export default Calendar;