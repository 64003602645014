import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
  Avatar,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { EmailOutlined, VpnKeyOutlined, SendOutlined, PhoneOutlined } from '@mui/icons-material';
import { getBaseUrl } from '../common';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '600px',
  margin: '40px auto',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[10],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius * 5,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  margin: '0 auto',
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
}));

function Settings() {

  const [pinId, setPinId] = useState('');
  const [newPhonePinId, setNewPhonePinId] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [changeType, setChangeType] = useState('');
  const [emailOtpTimer, setEmailOtpTimer] = useState(0);
  const [phoneOtpTimer, setPhoneOtpTimer] = useState(0);
  const [passwordOtpTimer, setPasswordOtpTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [requestId, setRequestId] = useState('');
  const [newPhoneOtp, setNewPhoneOtp] = useState('');
  const [newPhoneRequestId, setNewPhoneRequestId] = useState('');
  const [newPhoneOtpTimer, setNewPhoneOtpTimer] = useState(0);
  const [verifiedNewPhoneNumber, setVerifiedNewPhoneNumber] = useState('');
  const baseUrl = getBaseUrl();

  useEffect(() => {
    fetchUserData();
  }, []);


  useEffect(() => {
    const timers = [
      { timer: emailOtpTimer, setTimer: setEmailOtpTimer },
      { timer: phoneOtpTimer, setTimer: setPhoneOtpTimer },
      { timer: passwordOtpTimer, setTimer: setPasswordOtpTimer },
      { timer: newPhoneOtpTimer, setTimer: setNewPhoneOtpTimer }
    ];
  
    const intervals = timers.map(({ timer, setTimer }) => {
      if (timer > 0) {
        return setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }
      return null;
    });
  
    return () => intervals.forEach(interval => interval && clearInterval(interval));
  }, [emailOtpTimer, phoneOtpTimer, passwordOtpTimer, newPhoneOtpTimer]);
  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/user`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if (data.success) {
        setUsername(data.username);
        setPhoneNumber(data.phoneNumber);
      } else {
        showMessage(data.message, 'error');
        localStorage.removeItem('token');
        navigate(document.location.href = '/login');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('token');
      navigate('/login');
      showMessage('Error fetching user data', 'error');
    } finally {
      setIsLoading(false);
    }
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEmailOtpTimer(0);
    setPhoneOtpTimer(0);
    setPasswordOtpTimer(0);
    setNewPhoneOtpTimer(0);
    resetForm();
  };

  const handleChange = (type) => {
    setChangeType(type);
    setDialogOpen(true);
    resetForm();
  };

  const handleSendOTP = async (type) => {
    const timerMap = {
      email: emailOtpTimer,
      phone: phoneOtpTimer,
      password: passwordOtpTimer,
      newPhone: newPhoneOtpTimer
    };
    const setTimerMap = {
      email: setEmailOtpTimer,
      phone: setPhoneOtpTimer,
      password: setPasswordOtpTimer,
      newPhone: setNewPhoneOtpTimer
    };
  
    if (timerMap[type] > 0) return;
  
    // Sanitize phone numbers by removing spaces
    const sanitizedPhoneNumber = phoneNumber.replace(/\s/g, '');
    const sanitizedNewPhoneNumber = newPhoneNumber.replace(/\s/g, '');
  
    // Check if the new phone number is the same as the current one only when changing phone number
    if (type === 'newPhone') {
      if (sanitizedNewPhoneNumber.includes(sanitizedPhoneNumber) || sanitizedPhoneNumber.includes(sanitizedNewPhoneNumber)) {
        showMessage('New phone number is the same as the current one. Please enter a different number.', 'error');
        return;
      }
    }
  
    setIsLoading(true);
    try {
      let endpoint = '/send-otp';
      let body = { phoneNumber: type === 'newPhone' ? sanitizedNewPhoneNumber : sanitizedPhoneNumber };
  
      const response = await fetch(`${baseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      if (data.pinId) {
        showMessage('OTP sent successfully', 'success');
        setTimerMap[type](60);
        if (type === 'newPhone') {
          setNewPhonePinId(data.pinId);
          setVerifiedNewPhoneNumber(sanitizedNewPhoneNumber); // Store the sanitized verified number
        } else {
          setPinId(data.pinId);
        }
      } else {
        showMessage(`Failed to send OTP: ${data.message}`, 'error');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      showMessage(`Error sending OTP: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
    }
  }; 

  const refreshUserData = async (newToken) => {
    try {
      const response = await fetch(`${baseUrl}/user`, {
        headers: {
          'Authorization': `Bearer ${newToken}`
        }
      });
      const data = await response.json();
      if (data.success) {
        setUsername(data.username);
        setPhoneNumber(data.phoneNumber);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
      localStorage.removeItem('token');
      navigate('/login');
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    // Add a check for the verified phone number
  if (changeType === 'phone' && newPhoneNumber.replace(/\s/g, '') !== verifiedNewPhoneNumber) {
    showMessage('The phone number has changed since the OTP was sent. Please request a new OTP.', 'error');
    return;
  }
  
  setIsLoading(true);
  try {
    let endpoint;
    let body;

    if (changeType === 'email') {
      endpoint = '/change-email';
      body = { newEmail, currentPassword, otp, pinId };
    } else if (changeType === 'phone') {
      endpoint = '/change-phone';
      body = { newPhoneNumber, currentPassword, otp, pinId, newPhoneOtp, newPhonePinId };
    } else {
      endpoint = '/change-password';
      body = { currentPassword, newPassword, otp, pinId };
    }

    const response = await fetch(`${baseUrl}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(body)
    });
    const data = await response.json();
    if (data.success) {
      showMessage(`${changeType.charAt(0).toUpperCase() + changeType.slice(1)} changed successfully`, 'success');
      if (changeType === 'email' || changeType === 'phone') {
        localStorage.setItem('token', data.token);
        await refreshUserData(data.token);
      } else if (changeType === 'password') {
        // Show success message
        showMessage('Password changed successfully. Please log in again.', 'success');
        // Remove token
        localStorage.removeItem('token');
        // Set a timeout to allow the Snackbar to be visible before redirecting
        setTimeout(() => {
          navigate('/login');
        }, 2000); // 2000 milliseconds = 2 seconds
        return; // Exit the function early to prevent handleCloseDialog from being called
      }
      handleCloseDialog();
    } else {
      showMessage(data.message, 'error');
    }
  } catch (error) {
    console.error(`Error changing ${changeType}:`, error);
    showMessage(`Error changing ${changeType}`, 'error');
  } finally {
    setIsLoading(false);
  }
};

  const validateForm = () => {
    if (changeType === 'email') {
      if (!newEmail) {
        showMessage('New email is required', 'error');
        return false;
      }
      if (newEmail === username) {
        showMessage('You cannot use the same email', 'error');
        return false;
      }
    }
    if (changeType === 'phone') {
      if (!newPhoneNumber) {
        showMessage('New phone number is required', 'error');
        return false;
      }
      // Remove all spaces for validation
      const cleanedPhoneNumber = newPhoneNumber.replace(/\s/g, '');
      if (!/^\+?\d{6,}$/.test(cleanedPhoneNumber)) {
        showMessage('Invalid phone number format. Must contain at least 6 digits.', 'error');
        return false;
      }
      if (cleanedPhoneNumber === phoneNumber.replace(/\s/g, '')) {
        showMessage('You cannot use the same phone number', 'error');
        return false;
      }
      if (cleanedPhoneNumber !== verifiedNewPhoneNumber) {
        showMessage('The phone number has changed since the OTP was sent. Please request a new OTP.', 'error');
        return false;
      }
    }
    
    if (changeType === 'password') {
      if (!newPassword || !confirmPassword || newPassword !== confirmPassword) {
        showMessage('New password and confirm password must match', 'error');
        return false;
      }
      if (newPassword === currentPassword) {
        showMessage('New password must be different from the current password', 'error');
        return false;
      }
    }
    if (!otp) {
      showMessage('OTP is required', 'error');
      return false;
    }
    if (changeType === 'phone' && !newPhoneOtp) {
      showMessage('OTP for new phone number is required', 'error');
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setNewEmail('');
    setNewPhoneNumber('');
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setOtp('');
    setNewPhoneOtp('');
  };

  const showMessage = (msg, sev) => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <StyledPaper>
      <Box textAlign="center" mb={4}>
        <StyledAvatar sx={{ fontSize: '3rem' }}>
        </StyledAvatar>
        <Typography variant="h4" gutterBottom>Account Settings</Typography>
        <Typography variant="body1" color="textSecondary">
          Manage your account details and security
        </Typography>
      </Box>
  
      <Divider variant="middle" sx={{ my: 3 }} />
  
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <Box>
          <Typography variant="h6" gutterBottom align="center">
            Current Email
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.2rem', textAlign: 'center' }}>
            {username}
          </Typography>
        </Box>
      </Box>
  
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Current Phone Number
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.2rem', textAlign: 'center' }}>
            {phoneNumber}
          </Typography>
        </Box>
      </Box>
  
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Tooltip title="Change Email">
            <StyledButton
              variant="contained"
              color="primary"
              startIcon={<EmailOutlined />}
              onClick={() => handleChange('email')}
            >
              Change Email
            </StyledButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Change Phone Number">
            <StyledButton
              variant="contained"
              color="primary"
              startIcon={<PhoneOutlined />}
              onClick={() => handleChange('phone')}
            >
              Change Phone Number
            </StyledButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Change Password">
            <StyledButton
              variant="contained"
              color="secondary"
              startIcon={<VpnKeyOutlined />}
              onClick={() => handleChange('password')}
            >
              Change Password
            </StyledButton>
          </Tooltip>
        </Grid>
      </Grid>
  
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          {`Change ${changeType.charAt(0).toUpperCase() + changeType.slice(1)}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the required information to change your {changeType}.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            {changeType === 'email' && (
              <TextField
                margin="dense"
                label="New Email"
                type="email"
                fullWidth
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
                variant="outlined"
              />
            )}
            {changeType === 'phone' && (
              <>
<TextField
  margin="dense"
  label="New Phone Number"
  type="tel"
  fullWidth
  value={newPhoneNumber}
  onChange={(e) => {
    const value = e.target.value;
    if (value === '' || /^\+?[\d\s]*$/.test(value)) {
      setNewPhoneNumber(value);
    }
  }}
  required
  variant="outlined"
  inputProps={{
    pattern: "\\+?[\\d\\s]*",
    inputMode: "tel",
    onKeyPress: (event) => {
      const currentValue = event.target.value;
      if (!/[\d\s]/.test(event.key) && !(event.key === '+' && currentValue === '')) {
        event.preventDefault();
      }
    }
  }}
/>
<TextField
  margin="dense"
  label="OTP for New Phone"
  type="text"
  fullWidth
  value={newPhoneOtp}
  onChange={(e) => setNewPhoneOtp(e.target.value)}
  required
  variant="outlined"
  inputProps={{
    pattern: "[0-9]*",
    inputMode: "numeric",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  }}
  InputProps={{
    endAdornment: (
      <IconButton
        onClick={() => handleSendOTP('newPhone')}
        disabled={newPhoneOtpTimer > 0 || isLoading}
        color="primary"
      >
        <SendOutlined />
      </IconButton>
    ),
  }}
/>
                {newPhoneOtpTimer > 0 && (
                  <Typography variant="caption" color="textSecondary">
                    Resend OTP for new phone in {newPhoneOtpTimer}s
                  </Typography>
                )}
              </>
            )}
            {changeType === 'password' && (
              <>
                <TextField
                  margin="dense"
                  label="New Password"
                  type="password"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  variant="outlined"
                />
                <TextField
                  margin="dense"
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  variant="outlined"
                />
              </>
            )}
            <TextField
              margin="dense"
              label="Current Password"
              type="password"
              fullWidth
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              variant="outlined"
            />
            <TextField
  margin="dense"
  label="OTP for Current Phone"
  type="text"
  fullWidth
  value={otp}
  onChange={(e) => setOtp(e.target.value)}
  required
  variant="outlined"
  inputProps={{
    pattern: "[0-9]*",
    inputMode: "numeric",
    onKeyPress: (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  }}
  InputProps={{
    endAdornment: (
      <IconButton
        onClick={() => handleSendOTP(changeType)}
        disabled={
          (changeType === 'email' && emailOtpTimer > 0) ||
          (changeType === 'phone' && phoneOtpTimer > 0) ||
          (changeType === 'password' && passwordOtpTimer > 0) ||
          isLoading
        }
        color="primary"
      >
        <SendOutlined />
      </IconButton>
    ),
  }}
/>
            {changeType === 'email' && emailOtpTimer > 0 && (
              <Typography variant="caption" color="textSecondary">
                Resend OTP for email change in {emailOtpTimer}s
              </Typography>
            )}
            {changeType === 'phone' && phoneOtpTimer > 0 && (
              <Typography variant="caption" color="textSecondary">
                Resend OTP for phone change in {phoneOtpTimer}s
              </Typography>
            )}
            {changeType === 'password' && passwordOtpTimer > 0 && (
              <Typography variant="caption" color="textSecondary">
                Resend OTP for password change in {passwordOtpTimer}s
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
  
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
}

export default Settings;